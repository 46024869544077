import React from "react";

export const data_seo = {
  what_we_do: {
    title: "Dlaczego właśnie nam warto powierzyć swoją markę?",
    text: [
      <>
        <b>Skupiamy się w 100% na Tobie!</b> Przed rozpoczęciem prac dokładnie i
        szczegółowo wypytamy Cię o to, co chcesz osiągnąć, jaki jest Twój cel,
        jakimi wartościami kieruje się Twoja marka, jaka jest jej wizja i misja.
        Po wszystkich ustaleniach rozpoczniemy działania, które będą odpowiedzią
        na uzyskane informacje.
      </>,
      <>
        <b>Ciągle trzymamy rękę na pulsie!</b> Jesteśmy na bieżąco i stale
        śledzimy trendy w marketingu, dzięki czemu wiemy, jak za pomocą
        nowoczesnych technologii i najnowszych narzędzi sprawić, że Twoja marka
        z dnia na dzień będzie zarabiała więcej i zyskiwała nowych klientów.
      </>,
      <>
        <b>Nasze działania są długofalowe, a ich efekty długotrwałe.</b>{" "}
        Bezustannie analizujemy prowadzone działania, wyciągamy wnioski i
        wdrażamy zmiany. Twój sukces jest naszym sukcesem, dlatego nasze prace
        prowadzimy kompleksowo.
      </>,
      <>
        <b>
          Wiemy, co zrobić, aby Twoja marka skutecznie współpracowała z
          wyszukiwarkami internetowymi.
        </b>{" "}
        Zapewniamy pozycjonowanie stron internetowych. Tworzymy content, który
        jest zrozumiały dla Google. Pozycjonowanie SEO nie ma przed nami
        tajemnic.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Brief",
          "Analiza dotychczasowych działań marketingowych",
          "Analiza rynku i konkurencji",
          "Określenie grupy odbiorców",
          "Koncepcja kompleksowej kampanii reklamowej",
        ],
      },
      {
        title: "Przygotowanie",
        list: [
          "Konfiguracje narzędzi analitycznych - Google Tag Manager, Google Analytics",
          "Zebranie informacji / materiałów",
          "Opracowanie grafik reklamowych",
          "Utworzenie kampanii",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Bieżąca obsługa reklam",
          "Analiza i optymalizacja dotychczasowych działań",
          "Raportowanie",
        ],
      },
    ],
  },
};
